import { z } from 'zod';
import { AdminAppRoles } from '../auth';
import { passwordField, uuidSchema } from '../common';

export const createUserSchema = z
   .object({
      email: z.string().email(),
      roles: z.array(z.nativeEnum(AdminAppRoles)).min(1, {
         message: 'At least one role must be selected',
      }),
      password: passwordField,
      welkinUserId: uuidSchema.nullable(),
   })
   .superRefine((obj, ctx) => {
      if (obj.roles.includes(AdminAppRoles.Clinician) && !obj.welkinUserId) {
         ctx.addIssue({
            code: 'custom',
            fatal: true,
            message: 'Welkin user id must be set up for clinician users',
            path: ['welkinUserId'],
         });
      }
   });

export type CreateUserAdminDto = z.infer<typeof createUserSchema>;
