import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';
import { dateField, intExtendedField } from '../common';

export const marketingClinicianAvailabilityByDaySchema = z.object({
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
   timetapId: intExtendedField,
   date: dateField,
});

export type MarketingClinicianAvailabilityByDayDto = z.infer<
   typeof marketingClinicianAvailabilityByDaySchema
>;

export type MarketingClinicianAvailabilityByDayResponse = {
   slots: {
      startDatetime: string;
      endDatetime: string;
   }[];
};
