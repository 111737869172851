import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';

export const APPOINTMENT_RESERVATION_COOKIE_NAME = 'iw-appointment-reservation';

export const appointmentReservationSchema = z.object({
   clinician: z.object({
      timetapId: z.number(),
      name: z.string(),
      avatarUrl: z.string().nullable().optional(),
      pronouns: z.string(),
      licenseTypes: z.array(z.string()),
   }),
   slotStartDatetime: z.string(), // ISO Date
   slotEndDatetime: z.string(), // ISO Date
   reservationExpiresAt: z.string(), // ISO Date
   appointmentTemplate: z.nativeEnum(WelkinEncounterTemplateNames),
   insurance: z.union([z.literal(false), z.string()]).optional(),
});

export type AppointmentReservation = z.infer<
   typeof appointmentReservationSchema
>;
