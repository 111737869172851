import { ALL_PRECISION_SHIPPING_METHODS, CARRIERS } from '../precision';
import { z } from 'zod';
import { stateAbbrField, zipCodeField } from '../common';

export const fillShippingAddressSchema = z.object({
   address1: z.string().min(3, { message: 'Address 1 is required' }),
   address2: z.string().optional(),
   city: z.string().min(2, { message: 'City is required' }),
   state: stateAbbrField, // two letter code!
   zipCode: zipCodeField,
});

export type FillShippingAddressType = z.infer<typeof fillShippingAddressSchema>;

export const createFillSchema = z.object({
   externalPrescriptionIds: z.array(z.string().uuid()),
   carrier: z.nativeEnum(CARRIERS).optional(),
   shippingMethod: z.nativeEnum(ALL_PRECISION_SHIPPING_METHODS).optional(),
   shippingAddress: fillShippingAddressSchema,
});

export type CreateFillDto = z.infer<typeof createFillSchema>;
