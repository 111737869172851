import { z } from 'zod';
import { WelkinCdtEnum } from '../welkin';
import { stringToJSONSchema, uuidSchema } from '../common';
import { WelkinPrograms } from '../patients';

export const ServerSideEventType = {
   Cdt: 'cdt',
   EncounterDisposition: 'encounter-disposition',
   Encounter: 'encounter',
   Program: 'program',
   Patient: 'patient',
} as const;

export const serverSideEventPayloadSchema = z.discriminatedUnion('event', [
   z.object({
      event: z.literal(ServerSideEventType.Cdt),
      data: z.object({
         id: uuidSchema,
         cdtName: z.nativeEnum(WelkinCdtEnum),
         operation: z.enum(['create', 'delete', 'update']),
      }),
   }),
   z.object({
      event: z.literal(ServerSideEventType.EncounterDisposition),
      data: z.object({
         id: uuidSchema,
      }),
   }),
   z.object({
      event: z.literal(ServerSideEventType.Encounter),
      data: z.object({
         id: uuidSchema,
      }),
   }),
   z.object({
      event: z.literal(ServerSideEventType.Program),
      data: z.object({
         programName: z
            .nativeEnum(WelkinPrograms)
            .or(z.string().transform((v) => v as WelkinPrograms)),
      }),
   }),
   z.object({
      event: z.literal(ServerSideEventType.Patient),
      data: z.null(),
   }),
]);

// schema as seen by the client side where payload is a string
export const eventPayloadSchema = stringToJSONSchema.pipe(
   serverSideEventPayloadSchema,
);

export type ServerSideEventPayload = z.infer<
   typeof serverSideEventPayloadSchema
>;
