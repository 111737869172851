import {
   booleanExtendedField,
   dateTimeISO8601Field,
   intExtendedField,
} from '../common';
import { z } from 'zod';

export const defaultParamsSchema = z.object({
   dryRun: booleanExtendedField.optional(),
   timeout: intExtendedField.optional(),
});
export type DefaultParamsSchema = z.infer<typeof defaultParamsSchema>;

export const clinicianInputSchema = defaultParamsSchema.extend({
   hours: intExtendedField.optional(), // how much hours will be covered from start date
   start: dateTimeISO8601Field.optional(), // start date time from when to check encounters
});
export type ClinicianInputSchema = z.infer<typeof clinicianInputSchema>;

export const finishedThirdFollowUpSchema = defaultParamsSchema.extend({
   patientId: z.string().uuid().optional(),
});
export type FinishedThirdFollowUpSchema = z.infer<
   typeof finishedThirdFollowUpSchema
>;

export const defaultWithPatientIds = defaultParamsSchema.extend({
   patientIds: z.string().uuid().array(),
});
export type PatientList = z.infer<typeof defaultWithPatientIds>;

export const defaultWithOptionalPatientIds = defaultParamsSchema.extend({
   patientIds: z.string().uuid().array().optional(),
});

export type DefaultWithOptionalPatientIds = z.infer<
   typeof defaultWithOptionalPatientIds
>;

export const defaultWithOptionalPatientId = defaultParamsSchema.extend({
   patientId: z.string().uuid().optional(),
});

export type DefaultWithOptionalPatientId = z.infer<
   typeof defaultWithOptionalPatientId
>;

export const paymentsRetrySchema = defaultParamsSchema.extend({
   // useful for testing
   includeTestPatients: z.boolean().nullish(),
   data: z
      .array(
         z.object({
            transactionId: z.string().optional(),
            paymentFailedCdtId: z.string().uuid().optional(),
         }),
      )
      .optional(),
});
export type PaymentsRetrySchema = z.infer<typeof paymentsRetrySchema>;
