// Values correspond to the NMI plans, order templates etc
// These values are processed (lowecased & spaces are converted to underlines) on payment module
export enum TherapyPlan {
   // Appears deprecated
   Kap = 'KAP Plan', // was "1_on_1"

   // Active
   Foundation = 'Foundation', // new plan superseding MedicinePlan, with 8 dosing sessions -- was Medicine 8
   FoundationAndTherapy = 'Foundation + Therapy', // new plan superseding Kap8, with 8 dosing sessions -- was KAP 8
   Extended = 'Extended', // was Medicine plan, before that it was "byot"
   ExtendedAndTherapy = 'Extended + Therapy',
   EMDR = 'EMDR Plan',

   // New
   TwoDoseIntro = '2 Dose Intro',
   KetamineProgramConsult = 'Ketamine Program Consult',
   GeneralTherapyPlan = 'Therapy Plan',
   GeneralPsychiatryPlan = 'General Psychiatry Plan',

   Enthea = 'Enthea',

   // Insurance - without a plan (in intake)
   ConsultOnly = 'Consult Only',
}

// @NOTE: All these methods are for compatibility with the old system
// We changed the names of the plans and they have to match CDT (patient-chosen-care-plan-v2)
export const isExtended = (value: string | null) => {
   if (!value) {
      return false;
   }
   return [TherapyPlan.Extended, 'Extended Plan (24 doses)'].includes(value);
};

export const isFoundation = (value: string | null) => {
   if (!value) {
      return false;
   }
   return [TherapyPlan.Foundation, 'Foundation Plan'].includes(value);
};

export const isFoundationAndTherapy = (value: string | null) => {
   if (!value) {
      return false;
   }
   return [
      TherapyPlan.FoundationAndTherapy,
      'Foundation + Therapy Plan',
   ].includes(value);
};

export const isEmdr = (value: string | null) => {
   if (!value) {
      return false;
   }
   return [TherapyPlan.EMDR, 'EMDR'].includes(value);
};

export const isGeneralTherapyPlan = (value: string | null) => {
   if (!value) {
      return false;
   }
   return [TherapyPlan.GeneralTherapyPlan, 'General Therapy Plan'].includes(
      value,
   );
};

export const getPlanFromProduct = (value: string) => {
   if (isFoundation(value)) {
      return TherapyPlan.Foundation;
   }
   if (isFoundationAndTherapy(value)) {
      return TherapyPlan.FoundationAndTherapy;
   }
   if (isExtended(value)) {
      return TherapyPlan.Extended;
   }
   if (isEmdr(value)) {
      return TherapyPlan.EMDR;
   }
   if (isGeneralTherapyPlan(value)) {
      return TherapyPlan.GeneralTherapyPlan;
   }
   return value as TherapyPlan;
};
