export enum ErrorTypes {
   Welkin = 'welkin',
   Cognito = 'cognito',
   ValidationError = 'JoiValidationError',
   Magento = 'Magento',
   Timetap = 'Timetap',
   ClaimMd = 'ClaimMd',
   Precision = 'Precision',
   Payment = 'Payment',
   Scheduling = 'Scheduling',
   Nmi = 'NMI',
}
