import type { Prisma } from '@innerwell/prisma-app-generated';
import type { GetFindResult } from '@prisma/client/runtime/library';

export const SERVICES_SELECT = {
   id: true,
   name: true,
   slug: true,
   order: true,
} satisfies Prisma.ServiceSelect;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const args = {
   select: SERVICES_SELECT,
};

export type ServiceResponseDto = GetFindResult<
   Prisma.$ServicePayload,
   typeof args,
   unknown
>;
