import { z } from 'zod';
import { intExtendedField } from '../common';

export const FILTER_KEYS = {
   Username: 'username',
   Email: 'email',
} as const;

export type UsersFilterKey = (typeof FILTER_KEYS)[keyof typeof FILTER_KEYS];
const ATTRIBUTES = Object.values(FILTER_KEYS) as [
   UsersFilterKey,
   ...UsersFilterKey[],
];

export type AwsCognitoUsersFilterType = '^=' | '=';

export const usersFiltersSchema = z.object({
   attribute: z.enum(ATTRIBUTES),
   type: z.enum(['=', '^=']).optional(),
   value: z.string().min(3),
});

export type UsersFilters = z.infer<typeof usersFiltersSchema>;

export const listUsersAdminSchema = z.object({
   limit: intExtendedField.pipe(z.number().max(60).nullish()),
   pageToken: z.string().nullish(),
   filters: usersFiltersSchema.nullish(),
});
