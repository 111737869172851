import { type ApiResponseError } from '@innerwell/dtos';
import { BaseError, ErrorTypes } from '../../errors';

export type PrecisionErrorDto = {
   __baseError: boolean;
   type: ErrorTypes;
   message: string;
   errorCode: string;
};

export class PrecisionException extends BaseError {
   constructor(private errorResponse: ApiResponseError) {
      super(errorResponse.message);
   }

   toJSON(): PrecisionErrorDto {
      return {
         __baseError: true,
         type: ErrorTypes.Precision,
         message: this.errorResponse.message,
         errorCode: this.errorResponse.errorCode,
      };
   }
}
