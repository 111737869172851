import { z } from 'zod';
import {
   dateTimeField,
   intExtendedField,
   uuidSchema,
} from '../common/zod.fields';

export const createAdminEncounterSchema = z.object({
   patientId: z.string().uuid(),
   startDate: dateTimeField,
   endDate: dateTimeField.optional(),
   locationId: intExtendedField,
   serviceId: intExtendedField,
   clinicianId: uuidSchema.nullish(),
   overwrite: z.boolean().optional(),
});

export type CreateAdminEncounterDto = z.infer<
   typeof createAdminEncounterSchema
>;
