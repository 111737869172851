import { z } from 'zod';
import { WelkinEncounterTemplateNames } from '../welkin';
import { intExtendedField } from '../common';

export const marketingCliniciansAvailabilityByMonthSchema = z.object({
   templateName: z.nativeEnum(WelkinEncounterTemplateNames),
   timetapId: intExtendedField,
   month: intExtendedField,
   year: intExtendedField,
});

export type MarketingCliniciansAvailabilityByMonthDto = z.infer<
   typeof marketingCliniciansAvailabilityByMonthSchema
>;

export type MarketingCliniciansAvailabilityByMonthResponse = {
   availableDates: {
      date: string;
      hasOpenSlots: boolean;
   }[];
};
