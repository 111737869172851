import {
   type BillingInfoDto,
   type ConfirmDosageDto,
   type DefaultCdtData,
   type UpdateEmergencyContactDto,
   type UpdateSitterDto,
   WelkinCdtEnum,
} from '@innerwell/dtos';
import _ from 'lodash';
import { DateTime } from 'luxon';

export interface CDTRequest<Payload = DefaultCdtData> {
   name: string;
   payload: Payload;
}

interface CdtField {
   name: string;
   value: unknown;
}

abstract class WelkinAdapter {
   public payload: DefaultCdtData = {};
   public cdtName: WelkinCdtEnum;

   constructor(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      protected data: any,
      public prefix?: string,
   ) {
      this.adaptCdtFields();
   }

   adaptCdtFields() {
      for (const key in this.data) {
         const item = _.get(this, key) as
            | {
                 name: string;
                 value: unknown;
              }
            | undefined;
         if (item?.name) {
            _.set(this.payload, item.name, item.value);
         }
      }
   }
}

export class WelkinBillingInfo extends WelkinAdapter {
   public cdtName = WelkinCdtEnum.CdtShippingLog;
   constructor(
      protected data: BillingInfoDto,
      public prefix = 'cdtf-billing-',
   ) {
      super(data, prefix);
   }

   get address1(): CdtField {
      return {
         name: `${this.prefix}address`,
         value: `${this.data.address_1} ${this.data?.address_2 ?? ''}`,
      };
   }
   get city(): CdtField {
      return {
         name: `${this.prefix}city`,
         value: this.data.city,
      };
   }
   get state(): CdtField {
      return {
         name: `${this.prefix}state`,
         value: this.data.state,
      };
   }
   get zip(): CdtField {
      return {
         name: `${this.prefix}zip-code`,
         value: this.data.zipCode,
      };
   }
}

export class WelkinShippingInfo extends WelkinBillingInfo {
   constructor(protected data: BillingInfoDto) {
      super(data, 'cdtf-shipping-');
   }
}

export class WelkinSitterInfo extends WelkinAdapter {
   public cdtName = WelkinCdtEnum.CdtSitter;
   constructor(protected data: UpdateSitterDto) {
      super(data, 'cdtf-sitter-');
   }

   get name() {
      return {
         name: `${this.prefix ?? ''}name`,
         value: this.data.name,
      };
   }
   get phone() {
      return {
         name: `${this.prefix ?? ''}phone`,
         value: this.data.phone,
      };
   }
   get email() {
      return {
         name: `${this.prefix ?? ''}email`,
         value: this.data.email,
      };
   }
   get relationship() {
      return {
         name: `${this.prefix ?? ''}relationship`,
         value: this.data.relationship,
      };
   }

   get isEc() {
      return {
         name: 'cdtf-sitter_is_emergency_contact',
         value: this.data.isEc,
      };
   }

   get sitterVerified() {
      return {
         name: `${this.prefix ?? ''}verified`,
         value: this.data.sitterVerified,
      };
   }

   get sdsOneVerified() {
      return {
         name: 'cdtf-sds1-sitter-verifed',
         value: this.data.sdsOneVerified,
      };
   }

   get sdsTwoVerified() {
      return {
         name: 'cdtf-sds2-sitter-verifed',
         value: this.data.sdsTwoVerified,
      };
   }
}
export class WelkinEmergencyContactInfo extends WelkinAdapter {
   public cdtName = WelkinCdtEnum.CdtEmergencyContact;
   constructor(protected data: UpdateEmergencyContactDto) {
      super(data, 'cdtf-ec-');
   }

   get name() {
      return {
         name: `${this.prefix ?? ''}name`,
         value: this.data.name,
      };
   }
   get phone() {
      return {
         name: `${this.prefix ?? ''}phone`,
         value: this.data.phone,
      };
   }
   get email() {
      return {
         name: `${this.prefix ?? ''}email`,
         value: this.data.email,
      };
   }
   get relationship() {
      return {
         name: `${this.prefix ?? ''}relationship`,
         value: this.data.relationship,
      };
   }
}

// TODO: Make this derive from WelkinAdapter?
export class WelkinDosageConfirmation {
   public cdtName = WelkinCdtEnum.CdtDosageConfirmation;
   public payload: DefaultCdtData = {};

   constructor(private data: ConfirmDosageDto['dosage']) {
      for (const key in this.data) {
         const item = _.get(this.data, key, '');
         if (key.includes('date')) {
            _.set(
               this.payload,
               `cdtf-${key}`,
               DateTime.fromISO(item, {
                  zone: 'utc',
               }).toISO(),
            );
         } else {
            _.set(this.payload, `cdtf-${key}`, item);
         }
      }
   }
}

export class WelkinBloodPressure extends WelkinAdapter {
   public cdtName = WelkinCdtEnum.CdtBloodPressure;

   constructor(
      protected data: Required<ConfirmDosageDto>['bloodPressure'] & {
         date: string;
      },
      public prefix = 'cdtf-',
   ) {
      super(data, prefix);
   }

   get date() {
      return {
         name: `${this.prefix}date`,
         value: this.data.date,
      };
   }

   get what_is_the_bottom_number_dia() {
      return {
         name: `${this.prefix}what_is_the_bottom_number_dia`,
         value: this.data.what_is_the_bottom_number_dia,
      };
   }

   get what_is_the_top_number_systol() {
      return {
         name: `${this.prefix}what_is_the_top_number_systol`,
         value: this.data.what_is_the_top_number_systol,
      };
   }
}
