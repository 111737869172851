import { type AxiosError } from 'axios';
import { BaseError, BaseErrorResponse, ErrorTypes } from '../../errors';
import { getMessageFromAxiosError } from '../../errors/errors.utils';
import _ from 'lodash';

interface MagentoErrorResponse extends BaseErrorResponse {
   name: string;
   status: number | null;
   method: string;
   error: unknown;
}

// This is the actual error payload sent from the backend
export class MagentoError<T = unknown> extends BaseError {
   status: number | null;
   error: T | null;

   constructor(
      err: AxiosError<T>,
      public method: string,
   ) {
      super(getMessageFromAxiosError(err));

      this.type = ErrorTypes.Magento;

      // If response is not set, it isn't Magento error

      const response = err.response!;
      this.status = response.status;
      this.error = response.data;
   }

   toJSON(): MagentoErrorResponse {
      return {
         __baseError: true,
         type: ErrorTypes.Magento,
         name: this.name,
         message: this.message,
         status: this.status,
         method: this.method,
         error: this.error,
      };
   }
}

export function isMagentoErrorResponse(
   error: unknown,
): error is MagentoErrorResponse {
   return (
      _.isObject(error) && 'type' in error && error.type === ErrorTypes.Magento
   );
}
