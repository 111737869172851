import { z } from 'zod';
import { AdminAppRoles } from '../auth';

export const setRolesAdminSchema = z.object({
   roles: z.array(z.nativeEnum(AdminAppRoles)).min(1, {
      message: 'At least one role must be selected',
   }),
});

export type SetRolesAdminDto = z.infer<typeof setRolesAdminSchema>;
