export enum DosingFrequency {
   TwiceWeekly = 'Twice weekly',
   OnceWeekly = 'Once weekly',
   OnceEveryTwoWeeks = 'Once every other week',
   OnceMonthly = 'Once monthly',
   Other = 'Other',
   NotAvailable = 'N/A',
}

export interface CdtDoses {
   'cdtf-dose-1'?: string;
   'cdtf-dose-2'?: string;
   'cdtf-dose-for-the-series'?: string;
   'cdtf-dosing-frequency'?: string;
   'cdtf-dosing-frequency-other'?: string;
}
